.contain {
  margin: auto auto;
  padding: 2em 2em;
}
.section-wrapper {
  margin: 0 auto;
  border: 1px solid #e6e6e6;
  margin: 0px;
  padding: 8px 16px 8px 16px;
	border-radius: 0.3rem;
  box-shadow: 0px 2px 4px #e6e6e6;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.shadow {
  box-shadow: 0px 1px 2px #e6e6e6;
}

.ant-table-thead > tr > th {
  background-color:#157c3d !important;
  color: #ffff !important;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px;
}

.leaflet-container {
  z-index: 0;
  height: 100%;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.card-footer {
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.card-footer footer {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -15px;
  right: 0px;
  height: 25px;
  width: 100%;
}

.card-farmer button {
  position: relative;
  right: -20px;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}

.numpad {
  font-size: 2em;
  display: auto;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: fit-content;
  box-shadow: 0em 0em 0em 0em #00000055;
  padding: 0.2em;
  margin: auto;
  height: 200px;
}

.row-numpad {
  display: flex;
  align-items: center;
  justify-content: center;
}

.key-numpad {
  padding: 1em;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  border-radius: 10em;
  width: 5em;
  height: 5em;
}

.key-numpad:hover {
  background-color: #00000022;
}

.key-numpad-action {
  padding: 1em;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  border-radius: 10em;
  width: 5em;
  height: 5em;
  /* color: red */
}

.key-numpad-action:hover {
  background-color: #00000022;
}

.pincode {
  border: none;
  border-radius: 8px;
  font-size: 20px;
  margin: auto;
  width: 50%;
  padding: 10px;
  color: #272729;
}

.modalStyle {
  backdrop-filter: blur(10px);
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
          line-clamp: 2;
  -webkit-box-orient: vertical;
}

.table-row-green {
  background-color: #ccefd9;
}

.table-row-red {
  background-color: rgb(251, 212, 208);
}

.table-row-red:hover > td {
  background: unset !important;
}
